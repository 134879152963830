/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "lazysizes";

import React from "react";
import AppProvider from "~context/AppContext";
import AWSProvider from "~context/AWSContext";
import DocumentProvider from "~context/DocumentContext";
import Menu from "~components/Menu";
import Share from "~components/Share";

import "~scss/index.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <AWSProvider>
        <AppProvider>
          <DocumentProvider>
            <Menu />
            <Share />

            {element}
          </DocumentProvider>
        </AppProvider>
      </AWSProvider>
    </>
  );
};
