import React from "react";
import PropTypes from "prop-types";

const SVGArrow = props => (
  <svg
    className="w-full relative block"
    viewBox="0 0 56 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.2084 14.5884C45.2917 11.0275 20.87 6.0485 2.5166 14.62"
      stroke={props.color}
      strokeWidth="2"
    />
    <path
      d="M13.9176 17.5799C10.2625 16.6487 6.63819 15.6072 2.98215 14.6863C2.7448 14.6265 2.50586 14.5856 2.2694 14.5217C2.18908 14.5 2.09313 14.5151 2.02053 14.484C1.94987 14.4538 2.11928 14.4185 2.17514 14.3911C2.54744 14.2087 2.89292 14.0017 3.24364 13.8014C4.67099 12.9863 5.74253 11.9652 6.69324 10.852C7.46667 9.94634 8.11545 8.94792 8.56547 7.90496C8.65405 7.69968 8.75228 7.4901 8.81039 7.27498"
      stroke={props.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SVGArrow.defaultProps = {
  color: `#FF0000`
};

SVGArrow.propTypes = {
  color: PropTypes.string
};

export default SVGArrow;
