import React from "react";
import PropTypes from "prop-types";

import SVGClose from "~components/svg/SVGClose";

const Modal = ({ backgroundColor, children, className, closeAction }) => (
  <div
    className={`modal ${className} w-full h-full absolute top-0 right-0 bottom-0 left-0 z-30`}
  >
    {backgroundColor !== `` && (
      <div
        className={`modal__background w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-${backgroundColor}`}
      ></div>
    )}

    <div className="grid">
      <div className="modal__inner h-screen grid-end-10 md:grid-end-14 sm:grid-end-20 grid-start-8 md:grid-start-6 sm:grid-start-3 relative flex items-center justify-center">
        <article className="modal__content w-full relative bg-white text-black">
          {closeAction && (
            <button
              type="button"
              onClick={closeAction}
              className="modal__close absolute flex items-center justify-center z-50 cursor-pointer"
            >
              <SVGClose color="black" />
            </button>
          )}

          {children}
        </article>
      </div>
    </div>
  </div>
);

export default Modal;

Modal.defaultProps = {
  backgroundColor: ``,
  className: ``,
  closeAction: null
};

Modal.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeAction: PropTypes.func
};
