// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-persona-page-js": () => import("../src/templates/persona-page.js" /* webpackChunkName: "component---src-templates-persona-page-js" */),
  "component---src-templates-personas-page-js": () => import("../src/templates/personas-page.js" /* webpackChunkName: "component---src-templates-personas-page-js" */),
  "component---src-templates-quiz-page-js": () => import("../src/templates/quiz-page.js" /* webpackChunkName: "component---src-templates-quiz-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-results-page-js": () => import("../src/templates/results-page.js" /* webpackChunkName: "component---src-templates-results-page-js" */),
  "component---src-templates-start-page-js": () => import("../src/templates/start-page.js" /* webpackChunkName: "component---src-templates-start-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

