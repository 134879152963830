import React, { Component, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import PropTypes from "prop-types";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Modal from "~components/Modal";

import SVGFacebook from "~components/svg/SVGFacebook";
import SVGChain from "~components/svg/SVGChain";
import SVGTwitter from "~components/svg/SVGTwitter";

class ShareComponent extends Component {
  state = {
    copied: false,
    mounted: false
  };

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  //

  hide = () => {
    const { appContext } = this.props;

    appContext.setShareMenuActive(false);

    clearAllBodyScrollLocks();
  };

  //

  render() {
    const { appContext, documentContext } = this.props;
    const { copied, mounted } = this.state;

    return (
      <div
        className={`share w-full h-full fixed top-0 right-0 bottom-0 left-0 pointer-events-none z-40 ${
          mounted ? `mounted` : ``
        }`}
      >
        <Modal
          backgroundColor="black"
          className={`${
            appContext.shareMenuActive ? `modal--active` : `modal--inactive`
          }`}
          closeAction={this.hide}
        >
          <h2
            className={`${
              documentContext.device === `desktop` ? `mb-12` : `mb-8`
            } f3 text-center`}
          >
            Share the quiz
          </h2>

          <p className="b2 text-center">
            Always share the love!
            <br />
            ...and fun online quizzes.
          </p>

          <ul
            className={`${
              documentContext.device === `desktop`
                ? `w-1/3 mt-12`
                : `w-full mt-8 px-8`
            } relative flex items-center justify-between mx-auto`}
          >
            <li className="share__icon relative">
              <TwitterShareButton
                className="relative flex items-center justify-center flex-col cursor-pointer"
                url={appContext.shareUrl}
              >
                <SVGTwitter color="black" />

                <h4 className="mt-2 caption whitespace-no-wrap text-black">
                  Twitter
                </h4>
              </TwitterShareButton>
            </li>

            <li className="share__icon relative">
              <FacebookShareButton
                className="relative flex items-center justify-center flex-col cursor-pointer"
                url={appContext.shareUrl}
              >
                <SVGFacebook color="black" />

                <h4 className="mt-2 caption whitespace-no-wrap text-black">
                  Facebook
                </h4>
              </FacebookShareButton>
            </li>

            <li className="share__icon relative">
              <CopyToClipboard
                text={appContext.shareUrl}
                onCopy={() => {
                  this.setState({
                    copied: true
                  });
                }}
              >
                <div className="relative flex items-center justify-center flex-col cursor-pointer">
                  <SVGChain color="black" />

                  <h4 className="mt-2 caption whitespace-no-wrap text-black">
                    {(copied && `Copied!`) || `Copy link`}
                  </h4>
                </div>
              </CopyToClipboard>
            </li>
          </ul>
        </Modal>
      </div>
    );
  }
}

const Share = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <ShareComponent appContext={appContext} documentContext={documentContext} />
  );
};

export default Share;

//
// PropTypes

ShareComponent.defaultProps = {
  appContext: PropTypes.shape({
    setShareMenuActive: () => {},
    shareMenuActive: false
  })
};

ShareComponent.propTypes = {
  appContext: PropTypes.shape({
    setShareMenuActive: PropTypes.func,
    shareMenuActive: PropTypes.bool,
    shareUrl: PropTypes.string.isRequired
  }),
  documentContext: PropTypes.shape({
    device: PropTypes.string.isRequired
  }).isRequired
};
