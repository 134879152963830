import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import RotatingButtonLink from "~components/RotatingButtonLink";

import SVGArrow from "~components/svg/SVGArrow";

class MenuComponent extends Component {
  shareUrl = `https://www.myfoodie.id`;

  //

  hide = () => {
    this.props.appContext.setMenuActive(false);

    clearAllBodyScrollLocks();
  };

  share = () => {
    const { appContext, documentContext } = this.props;

    if (documentContext.device === `desktop` || !window.navigator.share) {
      this.hide();

      appContext.setShareMenuActive(true);

      return;
    }

    const title = `MYFOODIE.ID`;

    window.navigator.share({
      title,
      url: this.shareUrl
    });
  };

  //

  render() {
    const { appContext } = this.props;

    return (
      <div
        className={`menu w-full h-full fixed top-0 right-0 bottom-0 left-0 z-40 flex items-center justify-center gpu overflow-hidden bg-black ${
          appContext.menuActive
            ? `opacity-100 pointer-events-auto`
            : `opacity-0 pointer-events-none`
        }`}
      >
        {appContext.menuActive && (
          <ul className="relative z-20 grid gpu f1 text-center text-white">
            <li
              className="menu__link animation-appear grid-end-24 grid-start-1 relative"
              style={{ animationDelay: `100ms` }}
            >
              <Link to="/" onClick={this.hide}>
                <div className="menu__link__inner relative inline-block pointer-events-none">
                  <h3 className="menu__link__text">Home</h3>

                  <div
                    className={`menu__link__arrow absolute flex items-center justify-center flex-col pointer-events-none z-10 ${
                      appContext.activePath === `/` ? `` : `hidden`
                    }`}
                  >
                    <SVGArrow />
                    <span className="b3 text-red">You’re here</span>
                  </div>
                </div>
              </Link>
            </li>

            <li
              className="menu__link animation-appear grid-end-24 grid-start-1 relative"
              style={{ animationDelay: `150ms` }}
            >
              <Link to="/about" onClick={this.hide}>
                <div className="menu__link__inner relative inline-block pointer-events-none">
                  <h3 className="menu__link__text">About</h3>

                  <div
                    className={`menu__link__arrow absolute flex items-center justify-center flex-col pointer-events-none z-10 ${
                      appContext.activePath === `/about` ? `` : `hidden`
                    }`}
                  >
                    <SVGArrow />
                    <span className="b3 text-red">You’re here</span>
                  </div>
                </div>
              </Link>
            </li>

            <li
              className="menu__link animation-appear grid-end-24 grid-start-1 relative"
              style={{ animationDelay: `200ms` }}
            >
              <Link to="/start" onClick={this.hide}>
                <div className="menu__link__inner relative inline-block pointer-events-none">
                  <h3 className="menu__link__text">Start Quiz</h3>

                  <div
                    className={`menu__link__arrow absolute flex items-center justify-center flex-col pointer-events-none z-10 ${
                      appContext.activePath === `/start` ? `` : `hidden`
                    }`}
                  >
                    <SVGArrow />
                    <span className="b3 text-red">You’re here</span>
                  </div>
                </div>
              </Link>
            </li>

            <li
              className="menu__link animation-appear grid-end-24 grid-start-1 relative"
              style={{ animationDelay: `250ms` }}
            >
              <Link to="/personas" onClick={this.hide}>
                <div className="menu__link__inner relative inline-block pointer-events-none">
                  <h3 className="menu__link__text">FOOD.ID TYPES</h3>

                  <div
                    className={`menu__link__arrow absolute flex items-center justify-center flex-col pointer-events-none z-10 ${
                      appContext.activePath === `/personas` ? `` : `hidden`
                    }`}
                  >
                    <SVGArrow />
                    <span className="b3 text-red">You’re here</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        )}

        {appContext.menuActive && (
          <div className="menu__link menu__link--button animation-appear absolute right-0 bottom-0 pb-24 pr-16 sm:p-0 sm:pb-12">
            <RotatingButtonLink
              className="infinispin"
              onClick={this.share}
              text="Share"
            />
          </div>
        )}
      </div>
    );
  }
}

MenuComponent.propTypes = {
  appContext: PropTypes.shape({
    activePath: PropTypes.string,
    setShareMenuActive: PropTypes.func,
    setMenuActive: PropTypes.func,
    menuActive: PropTypes.bool
  }).isRequired,
  documentContext: PropTypes.shape({
    device: PropTypes.string.isRequired
  }).isRequired
};

//

const Menu = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <MenuComponent appContext={appContext} documentContext={documentContext} />
  );
};

export default Menu;
