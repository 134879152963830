import React from "react";
import PropTypes from "prop-types";

const SVGTwitter = props => {
  // TODO: as import
  const colors = {
    black: `#000000`,
    blackish: `#030303`,
    camelot: `#9e3259`,
    cararra: `#eff0e8`,
    brown: `#BEB4A5`,
    geyser: `#cfd8e3`,
    green: `#65dba2`,
    grey: `#d1d1d1`,
    "light-grey": `#E4E5DD`,
    mimosa: `#f4ffb2`,
    minsk: `#3622b0`,
    monalisa: `#ffa99d`,
    peach: `#ffc4c0`,
    pink: `#f3adcf`,
    purple: `#581aa1`,
    red: `#ff0000`,
    salem: `#077d4b`,
    sienna: `#76161d`,
    sisal: `#c3b8a6`,
    teal: `#008888`,
    burntish: `#9E9483`,
    burnt: `#B0A592`,
    burnter: `#C3B8A6`,
    burntest: `#E6DFD3`,
    ultramarine: `#0f00be`,
    wheat: `#f3d5a8`,
    white: `#ffffff`,
    yellow: `#ffd81a`
  };

  return (
    <svg
      className="w-full relative block"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22772 16.8658C6.89109 16.8351 6.60594 16.8198 6.32475 16.7584C4.56634 16.3671 3.35842 15.3505 2.68515 13.7354C2.60198 13.5359 2.6099 13.5321 2.8198 13.5628C3.47327 13.6587 4.11881 13.6165 4.8 13.4515C4.57822 13.3863 4.39604 13.3441 4.21782 13.2789C2.49109 12.6574 1.41386 11.4759 0.982178 9.74188C0.910891 9.45033 0.887129 9.1511 0.879208 8.84804C0.875248 8.70993 0.906931 8.69459 1.0297 8.75597C1.59208 9.03985 2.1901 9.20097 2.8198 9.26235C2.89109 9.27003 2.95842 9.27003 3.04158 9.25852C2.01188 8.51428 1.31881 7.56289 1.03762 6.3468C0.760396 5.13455 0.946535 3.99134 1.57624 2.89417C1.83762 3.17038 2.07921 3.44276 2.33267 3.69595C4.23366 5.61024 6.51089 6.88388 9.17228 7.52069C9.9802 7.71634 10.804 7.81609 11.6317 7.88898C11.798 7.90432 11.7822 7.83143 11.7624 7.72402C11.5881 6.83017 11.6396 5.95167 11.9881 5.10769C12.6218 3.56168 13.7743 2.54124 15.4455 2.14994C17.196 1.7433 18.7485 2.16912 20.0634 3.3622C20.1782 3.46961 20.2733 3.50414 20.4436 3.46961C21.402 3.25862 22.3089 2.93254 23.1644 2.46068C23.2158 2.43382 23.2713 2.41081 23.3624 2.36861C23.1921 2.93637 22.9069 3.40823 22.5584 3.84557C22.2099 4.27906 21.7901 4.64351 21.3149 4.96191C22.2416 4.87752 23.1208 4.62816 24 4.31359C23.8297 4.53609 23.6713 4.76627 23.4891 4.97726C22.9822 5.56804 22.4238 6.11279 21.7782 6.56547C21.6158 6.68056 21.5723 6.80332 21.5762 6.99896C21.6356 9.33141 21.0851 11.5334 20.004 13.6127C19.0495 15.4617 17.7307 17.0384 16.0277 18.3044C14.7485 19.2558 13.3386 19.9578 11.7941 20.4028C9.77426 20.9859 7.71881 21.1394 5.62772 20.8747C3.60396 20.6177 1.73465 19.9463 0 18.8798C2.65347 19.087 5.05743 18.4579 7.22772 16.8658Z"
        fill={colors[props.color]}
      />
    </svg>
  );
};

SVGTwitter.defaultProps = {
  color: `white`
};

SVGTwitter.propTypes = {
  color: PropTypes.string
};

export default SVGTwitter;
