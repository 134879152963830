/* eslint-disable react/sort-comp */

import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import { fancyWarning } from "~utils/helpers";

const AWS = require(`aws-sdk`);

export const AWSContext = createContext({});

class AWSProvider extends Component {
  state = {
    data: null
  };

  bucketName = `relishworks.com`;

  s3;

  componentDidMount() {
    if (!process.env.GATSBY_AWS_ACCESS || !process.env.GATSBY_AWS_SECRET) {
      fancyWarning(new Error(`AWS environment variables unset`));

      return;
    }

    AWS.config.update({
      credentials: {
        accessKeyId: process.env.GATSBY_AWS_ACCESS,
        secretAccessKey: process.env.GATSBY_AWS_SECRET
      }
    });

    this.s3 = new AWS.S3({ region: `us-east-1` });

    this.cache();
  }

  //

  cache = () => {
    if (this.s3) {
      this.getS3(`quiz-results.json`).then(data => {
        data.forEach(row => {
          if (row.key === `Moody Foodie`) {
            row.key = `Moodie Foodie`;
          }

          if (row.key === `Powersnacker` || row.key === `Powerfeaster`) {
            row.key = `Flavor Townie`;
          }
        });

        this.setState({
          data
        });
      });
    }
  };

  //

  getS3 = key => {
    return new Promise((resolve, reject) => {
      if (!this.s3) {
        reject(new Error(`S3 not initialized`));
      }

      this.s3.getObject({ Bucket: this.bucketName, Key: key }, (err, data) => {
        if (err) {
          reject(err);

          return;
        }

        const parsedData = JSON.parse(data.Body.toString(`utf-8`));

        resolve(parsedData);
      });
    });
  };

  putS3 = (key, body) => {
    return new Promise((resolve, reject) => {
      if (!this.s3) {
        reject(new Error(`S3 not initialized`));
      }

      this.s3.putObject(
        {
          Body: JSON.stringify(body),
          Bucket: this.bucketName,
          Key: key
        },
        (err, data) => {
          if (err) {
            reject(new Error(`AWS put request failed: `, err));

            return;
          }

          resolve(data);
        }
      );
    });
  };

  //

  saveQuizResult = quizKey => {
    return new Promise((resolve, reject) => {
      if (!quizKey || quizKey === ``) {
        reject(new Error(`Missing data`));
      }

      if (!this.s3) {
        reject(new Error(`S3 not initialized`));
      }

      this.getS3(`quiz-results.json`).then(quizResults => {
        let existingResultIndex = null;

        quizResults.forEach((result, index) => {
          if (existingResultIndex) {
            return;
          }

          const resultIndex = index;

          if (result.key === quizKey) {
            existingResultIndex = resultIndex;
          }
        });

        let quizResult = null;

        if (!existingResultIndex) {
          const newQuizResult = {
            key: quizKey,
            count: 10
          };

          quizResults.push(newQuizResult);
          quizResult = newQuizResult;
        } else {
          quizResults[existingResultIndex].count += 1;

          quizResult = quizResults[existingResultIndex];
        }

        this.putS3(`quiz-results.json`, quizResults).then(() => {
          resolve(quizResult);
        });
      });
    });
  };

  //

  render() {
    return (
      <AWSContext.Provider
        value={{
          data: this.state.data,
          getS3: this.getS3,
          putS3: this.putS3,
          saveQuizResult: this.saveQuizResult
        }}
      >
        {this.props.children}
      </AWSContext.Provider>
    );
  }
}

AWSProvider.propTypes = {
  children: PropTypes.shape({}).isRequired
};

export default AWSProvider;
