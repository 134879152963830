import React from "react";
import PropTypes from "prop-types";

const SVGChain = props => {
  // TODO: as import
  const colors = {
    black: `#000000`,
    blackish: `#030303`,
    camelot: `#9e3259`,
    cararra: `#eff0e8`,
    brown: `#BEB4A5`,
    geyser: `#cfd8e3`,
    green: `#65dba2`,
    grey: `#d1d1d1`,
    "light-grey": `#E4E5DD`,
    mimosa: `#f4ffb2`,
    minsk: `#3622b0`,
    monalisa: `#ffa99d`,
    peach: `#ffc4c0`,
    pink: `#f3adcf`,
    purple: `#581aa1`,
    red: `#ff0000`,
    salem: `#077d4b`,
    sienna: `#76161d`,
    sisal: `#c3b8a6`,
    teal: `#008888`,
    burntish: `#9E9483`,
    burnt: `#B0A592`,
    burnter: `#C3B8A6`,
    burntest: `#E6DFD3`,
    ultramarine: `#0f00be`,
    wheat: `#f3d5a8`,
    white: `#ffffff`,
    yellow: `#ffd81a`
  };

  return (
    <svg
      className="w-full relative block"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4435 11.0641L16.6896 14.8165C14.6158 16.8896 11.2557 16.8896 9.18622 14.8165C8.85808 14.4929 8.60432 14.1255 8.38119 13.745L10.1269 12C10.21 11.9169 10.3106 11.8688 10.4113 11.8119C10.5338 12.223 10.7438 12.6123 11.0675 12.9359C12.1001 13.9724 13.7845 13.9681 14.8214 12.9359L18.5753 9.18347C19.6122 8.14695 19.6122 6.46753 18.5753 5.43101C17.5428 4.39886 15.8584 4.39886 14.8214 5.43101L13.487 6.76492C12.402 6.34507 11.2382 6.23136 10.1006 6.39755L12.9445 3.55478C15.0183 1.48174 18.3784 1.48174 20.4523 3.55478C22.5174 5.63219 22.5174 8.99103 20.4435 11.0641ZM10.5206 17.2307L9.18622 18.5646C8.15368 19.5968 6.46924 19.5968 5.43232 18.5646C4.39978 17.5281 4.39978 15.8487 5.43232 14.8122L9.18622 11.0597C10.2231 10.0232 11.9032 10.0232 12.9401 11.0597C13.2639 11.3833 13.4739 11.7726 13.5964 12.1837C13.6926 12.1268 13.7976 12.0787 13.8808 11.9956L15.6265 10.255C15.4033 9.87448 15.1452 9.51148 14.8214 9.18347C12.752 7.11043 9.38747 7.11043 7.31802 9.18347L3.55537 12.9403C1.48154 15.0133 1.48154 18.3722 3.55537 20.4452C5.6292 22.5183 8.98933 22.5183 11.0588 20.4452L13.9026 17.6024C12.7695 17.7643 11.6013 17.6506 10.5206 17.2307Z"
        fill={colors[props.color]}
      />
    </svg>
  );
};

SVGChain.defaultProps = {
  color: `white`
};

SVGChain.propTypes = {
  color: PropTypes.string
};

export default SVGChain;
