import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { getWindowDimensions } from "~utils/dom";

export const DocumentContext = createContext({});

class DocumentProvider extends Component {
  state = {
    cursorCenterDeltaX: 0,
    cursorCenterDeltaY: 0,
    cursorPositionX: 0,
    cursorPositionY: 0,
    device: `desktop`,
    scrollTop: 0,
    windowHeight: 0,
    windowWidth: 0
  };

  mobileWidth = 768;

  tabletWidth = 1024;

  //

  componentDidMount() {
    if (document) {
      document.addEventListener(
        `mousemove`,
        _.throttle(this.handleMousemove),
        false
      );

      document.addEventListener(`scroll`, _.throttle(this.handleScroll), false);
    }

    if (window) {
      window.addEventListener(`resize`, _.throttle(this.handleResize), false);

      setTimeout(() => {
        this.handleResize();
      });
    }
  }

  //

  handleMousemove = event => {
    this.setState(prevState => ({
      cursorCenterDeltaX: -(0.5 - event.pageX / prevState.windowWidth),
      cursorPositionX: event.pageX,
      cursorCenterDeltaY: -(
        0.5 -
        (event.pageY - window.pageYOffset) / prevState.windowHeight
      ),
      cursorPositionY: event.pageY - window.pageYOffset
    }));
  };

  handleResize = () => {
    let device = `desktop`;

    if (
      window.matchMedia(
        `(min-width: ${this.mobileWidth}px) and (max-width: ${this.tabletWidth}px)`
      ).matches
    ) {
      device = `tablet`;
    } else if (
      window.matchMedia(`(max-width: ${this.mobileWidth - 1}px)`).matches
    ) {
      device = `mobile`;
    }

    this.setState({
      device,
      windowHeight: getWindowDimensions().height,
      windowWidth: getWindowDimensions().width
    });
  };

  handleScroll = e => {
    if (!e.target.scrollingElement || e.target.scrollingElement.scrollTop) {
      return;
    }

    this.setState({
      scrollTop: e.target.scrollingElement.scrollTop
    });
  };

  // touchStart(e) {
  //   this.firstClientX = e.touches[0].clientX;
  //   this.firstClientY = e.touches[0].clientY;
  // }

  // // eslint-disable-next-line consistent-return
  // preventTouch(e) {
  //   const minValue = 5;

  //   this.clientX = e.touches[0].clientX - this.firstClientX;
  //   this.clientY = e.touches[0].clientY - this.firstClientY;

  //   if (Math.abs(this.clientX) > minValue) {
  //     e.preventDefault();
  //     e.returnValue = false;

  //     return false;
  //   }
  // }

  //

  render() {
    return (
      <DocumentContext.Provider
        value={{
          cursorCenterDeltaX: this.state.cursorCenterDeltaX,
          cursorCenterDeltaY: this.state.cursorCenterDeltaY,
          cursorPositionX: this.state.cursorPositionX,
          cursorPositionY: this.state.cursorPositionY,
          device: this.state.device,
          scrollTop: this.state.scrollTop,
          windowHeight: this.state.windowHeight,
          windowWidth: this.state.windowWidth
        }}
      >
        {this.props.children}
      </DocumentContext.Provider>
    );
  }
}

DocumentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DocumentProvider;
