import React from "react";
import PropTypes from "prop-types";

const SVGFacebook = props => {
  // TODO: as import
  const colors = {
    black: `#000000`,
    blackish: `#030303`,
    camelot: `#9e3259`,
    cararra: `#eff0e8`,
    brown: `#BEB4A5`,
    geyser: `#cfd8e3`,
    green: `#65dba2`,
    grey: `#d1d1d1`,
    "light-grey": `#E4E5DD`,
    mimosa: `#f4ffb2`,
    minsk: `#3622b0`,
    monalisa: `#ffa99d`,
    peach: `#ffc4c0`,
    pink: `#f3adcf`,
    purple: `#581aa1`,
    red: `#ff0000`,
    salem: `#077d4b`,
    sienna: `#76161d`,
    sisal: `#c3b8a6`,
    teal: `#008888`,
    burntish: `#9E9483`,
    burnt: `#B0A592`,
    burnter: `#C3B8A6`,
    burntest: `#E6DFD3`,
    ultramarine: `#0f00be`,
    wheat: `#f3d5a8`,
    white: `#ffffff`,
    yellow: `#ffd81a`
  };

  return (
    <svg viewBox="0 0 28 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33337 2.33331C3.67652 2.33331 2.33337 3.67646 2.33337 5.33331V23.8333C2.33337 25.4902 3.67652 26.8333 5.33337 26.8333H15.4295L15.4 17.8182H11.6667V14.2121H15.4V11.9583C15.4 8.61243 17.5451 6.99998 20.6352 6.99998C22.1154 6.99998 23.3876 7.10642 23.7583 7.154V10.6506L21.6152 10.6516C19.9346 10.6516 19.6092 11.4229 19.6092 12.5549V14.2121H24.5L22.6334 17.8182H19.6092V26.8333H23.8334C25.4902 26.8333 26.8334 25.4902 26.8334 23.8333V5.33331C26.8334 3.67646 25.4902 2.33331 23.8334 2.33331H5.33337Z"
        fill={colors[props.color]}
      />
    </svg>
  );
};

SVGFacebook.defaultProps = {
  color: `white`
};

SVGFacebook.propTypes = {
  color: PropTypes.string
};

export default SVGFacebook;
