import React from "react";
import PropTypes from "prop-types";

const SVGClose = props => (
  <svg
    className="w-full relative block"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 18L18 6M18 18L6.5 6" stroke={props.color} strokeWidth="2" />
  </svg>
);

SVGClose.defaultProps = {
  color: `white`
};

SVGClose.propTypes = {
  color: PropTypes.string
};

export default SVGClose;
