import React, { Component, createContext } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    activePath: `/`,
    newsletterActive: false,
    quizResult: null,
    quizState: -1,
    shareMenuActive: false,
    shareUrl: `https://www.myfoodie.id`,
    menuActive: false
  };

  //

  setActivePath = activePath => {
    this.setState({
      activePath
    });
  };

  setMenuActive = menuActive => {
    this.setState({
      menuActive
    });
  };

  setNewsletterActive = newsletterActive => {
    this.setState({
      newsletterActive
    });
  };

  setQuizResult = quizResult => {
    this.setState({
      quizResult
    });
  };

  setQuizState = quizState => {
    this.setState({
      quizState
    });
  };

  setShareMenuActive = shareMenuActive => {
    this.setState({
      shareMenuActive
    });
  };

  setShareUrl = shareUrl => {
    this.setState({
      shareUrl
    });
  };

  //

  render() {
    return (
      <AppContext.Provider
        value={{
          activePath: this.state.activePath,
          menuActive: this.state.menuActive,
          newsletterActive: this.state.newsletterActive,
          quizResult: this.state.quizResult,
          quizState: this.state.quizState,
          shareMenuActive: this.state.shareMenuActive,
          shareUrl: this.state.shareUrl,
          //
          setActivePath: this.setActivePath,
          setMenuActive: this.setMenuActive,
          setNewsletterActive: this.setNewsletterActive,
          setQuizResult: this.setQuizResult,
          setQuizState: this.setQuizState,
          setShareMenuActive: this.setShareMenuActive,
          setShareUrl: this.setShareUrl
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
